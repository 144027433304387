@import './swiper-vars.scss';

$swiper-navigation-size: 24px;

.swiper-button-prev {
  position: absolute;
  top: 50%;
  left: 4px;
  right: auto;
  width: $swiper-navigation-size;
  height: $swiper-navigation-size;
  margin-top: calc(0px - ($swiper-navigation-size / 2));
  z-index: 5;
  cursor: pointer;
  background-image: url('https://static.zaritalk.com/images/ic-16-left-arrow%403x.png');
  background-size: cover;

  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
}

.swiper-button-next {
  @extend .swiper-button-prev;
  background-image: url('https://static.zaritalk.com/images/ic-16-right-arrow%403x.png');
  right: 4px;
  left: auto;
}

.swiper-button-lock {
  display: none;
}
