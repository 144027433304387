:root {
  --fg-color:#37352f;
  --fg-color-0:rgba(55,53,47,0.09);
  --fg-color-1:rgba(55,53,47,0.16);
  --fg-color-2:rgba(55,53,47,0.4);
  --fg-color-3:rgba(55,53,47,0.6);
  --fg-color-4:#000;
  --fg-color-5:rgba(55,53,47,0.024);
  --fg-color-6:rgba(55,53,47,0.8);

  --fg-color-icon:var(--fg-color);

  --bg-color:#fff;
  --bg-color-0:rgba(135,131,120,0.15);
  --bg-color-1:#f7f6f3;
  --bg-color-2:rgba(135,131,120,0.15);

  --select-color-0:#2eaadc;
  --select-color-1:rgba(45,170,219,0.3);
  --select-color-2:#d9eff8;

  --notion-red:#e03e3e;
  --notion-pink:#ad1a72;
  --notion-blue:#0b6e99;
  --notion-purple:#6940a5;
  --notion-teal:#0f7b6c;
  --notion-yellow:#dfab01;
  --notion-orange:#d9730d;
  --notion-brown:#64473a;
  --notion-gray:#9b9a97;

  --notion-red_background:#fbe4e4;
  --notion-pink_background:#f4dfeb;
  --notion-blue_background:#ddebf1;
  --notion-purple_background:#eae4f2;
  --notion-teal_background:#ddedea;
  --notion-yellow_background:#fbf3db;
  --notion-orange_background:#faebdd;
  --notion-brown_background:#e9e5e3;
  --notion-gray_background:#ebeced;

  --notion-red_background_co:rgba(251,228,228,0.3);
  --notion-pink_background_co:rgba(244,223,235,0.3);
  --notion-blue_background_co:rgba(221,235,241,0.3);
  --notion-purple_background_co:rgba(234,228,242,0.3);
  --notion-teal_background_co:rgba(221,237,234,0.3);
  --notion-yellow_background_co:rgba(251,243,219,0.3);
  --notion-orange_background_co:rgba(250,235,221,0.3);
  --notion-brown_background_co:rgba(233,229,227,0.3);
  --notion-gray_background_co:rgba(235,236,237,0.3);

  --notion-item-blue:rgba(0,120,223,0.2);
  --notion-item-orange:rgba(245,93,0,0.2);
  --notion-item-green:rgba(0,135,107,0.2);
  --notion-item-pink:rgba(221,0,129,0.2);
  --notion-item-brown:rgba(140,46,0,0.2);
  --notion-item-red:rgba(255,0,26,0.2);
  --notion-item-yellow:rgba(233,168,0,0.2);
  --notion-item-default:rgba(206,205,202,0.5);
  --notion-item-purple:rgba(103,36,222,0.2);
  --notion-item-gray:hsla(45,2%,60%,0.4);

  --notion-max-width:720px;
  --notion-header-height:45pxs
}

.dark-mode {
  --fg-color:hsla(0,0%,100%,0.9);
  --fg-color-0:var(--fg-color);
  --fg-color-1:var(--fg-color);
  --fg-color-2:var(--fg-color);
  --fg-color-3:var(--fg-color);
  --fg-color-4:var(--fg-color);
  --fg-color-5:hsla(0,0%,100%,0.7);
  --fg-color-6:#fff;

  --fg-color-icon:#fff;

  --bg-color:#2f3437;
  --bg-color-0:#474c50;
  --bg-color-1:#3f4447;
  --bg-color-2:rgba(135,131,120,0.15);

  --notion-red:#ff7369;
  --notion-pink:#e255a1;
  --notion-blue:#529cca;
  --notion-purple:#9a6dd7;
  --notion-teal:#4dab9a;
  --notion-yellow:#ffdc49;
  --notion-orange:#ffa344;
  --notion-brown:#937264;
  --notion-gray:hsla(195,2%,60%,0.95);

  --notion-red_background:#594141;
  --notion-pink_background:#533b4c;
  --notion-blue_background:#364954;
  --notion-purple_background:#443f57;
  --notion-teal_background:#354c4b;
  --notion-yellow_background:#59563b;
  --notion-orange_background:#594a3a;
  --notion-brown_background:#434040;
  --notion-gray_background:#454b4e;

  --notion-red_background_co:rgba(89,65,65,0.3);
  --notion-pink_background_co:rgba(83,59,76,0.3);
  --notion-blue_background_co:rgba(120,162,187,0.3);
  --notion-purple_background_co:rgba(68,63,87,0.3);
  --notion-teal_background_co:rgba(53,76,75,0.3);
  --notion-yellow_background_co:rgba(89,86,59,0.3);
  --notion-orange_background_co:rgba(89,74,58,0.3);
  --notion-brown_background_co:rgba(67,64,64,0.3);
  --notion-gray_background_co:rgba(69,75,78,0.3)}

.notion {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  color: var(--fg-color);
  caret-color: var(--fg-color);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: var(--bg-color);
}

.notion > *,
.notion-page > *,
.notion-column > * {
  padding: 3px 0px;
}

.notion * {
  box-sizing: border-box;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.notion-red {
  color: var(--notion-red);
}
.notion-pink {
  color: var(--notion-pink);
}
.notion-blue {
  color: var(--notion-blue);
}
.notion-purple {
  color: var(--notion-purple);
}
.notion-teal {
  color: var(--notion-teal);
}
.notion-yellow {
  color: var(--notion-yellow);
}
.notion-orange {
  color: var(--notion-orange);
}
.notion-brown {
  color: var(--notion-brown);
}
.notion-gray {
  color: var(--notion-gray);
}
.notion-red_background {
  background-color: var(--notion-red_background);
}
.notion-pink_background {
  background-color: var(--notion-pink_background);
}
.notion-blue_background {
  background-color: var(--notion-blue_background);
}
.notion-purple_background {
  background-color: var(--notion-purple_background);
}
.notion-teal_background {
  background-color: var(--notion-teal_background);
}
.notion-yellow_background {
  background-color: var(--notion-yellow_background);
}
.notion-orange_background {
  background-color: var(--notion-orange_background);
}
.notion-brown_background {
  background-color: var(--notion-brown_background);
}
.notion-gray_background {
  background-color: var(--notion-gray_background);
}
.notion-red_background_co {
  background-color: var(--notion-red_background_co);
}
.notion-pink_background_co {
  background-color: var(--notion-prink_background_co);
}
.notion-blue_background_co {
  background-color: var(--notion-blue_background_co);
}
.notion-purple_background_co {
  background-color: var(--notion-purple_background_co);
}
.notion-teal_background_co {
  background-color: var(--notion-teal_background_co);
}
.notion-yellow_background_co {
  background-color: var(--notion-yellow_background_co);
}
.notion-orange_background_co {
  background-color: var(--notion-orange_background_co);
}
.notion-brown_background_co {
  background-color: var(--notion-brown_background_co);
}
.notion-gray_background_co {
  background-color: var(--notion-gray_background_co);
}

.notion b {
  display: inline-block;
  font-weight: 600;
}

.notion-title {
  font-size: 1.75em;
  font-weight: 700;
  margin-top: 0.75em;
  margin-bottom: 0.25em;

  white-space: pre-wrap;
  word-break: keep-all;
}

.notion-h1,
.notion-h2,
.notion-h3 {
  font-weight: 600;
  line-height: 1.3;
  padding: 3px 2px;
}

.notion-h1 {
  font-size: 1.875em;
  margin-top: 1.4em;
}
.notion-h1:first-child {
  margin-top: 0;
}
.notion-h2 {
  font-size: 1.25em;
  margin-top: 1.1em;
}
.notion-h3 {
  font-size: 1.25em;
  margin-top: 1em;
}
.notion-emoji {
  font-family: "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
  "Segoe UI Symbol";
}
.notion-page-cover {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 30vh;
  min-height: 30vh;
  padding: 0;
}

.notion-page {
  padding: 0;
  margin: 0 auto;
  max-width: 708px;
  width: 100%;
}

@media only screen and (max-width: 730px) {
  .notion-page {
    padding: 0 2vw;
  }
}

.notion-page-offset {
  margin-top: 76px;

  padding: 0 24px;
}

@media only screen and (max-width: 730px) {
  .notion-page-offset {
    margin-top: 76px;

    padding: 0 24px;
  }
}

span.notion-page-icon-cover {
  height: 78px;
  width: 78px;
  font-size: 78px;
  display: inline-block;
  line-height: 1.1;
  margin-left: 0px;
}

span.notion-page-icon-offset {
  margin-top: -42px;
}

img.notion-page-icon-cover {
  border-radius: 3px;
  width: 124px;
  height: 124px;
  margin: 8px;
}

img.notion-page-icon-offset {
  margin-top: -80px;
}

.notion-full-width {
  padding: 0 40px;
  max-width: 100%;
}

.notion-small-text {
  font-size: 14px;
}
.notion-quote {
  white-space: pre-wrap;
  word-break: break-word;
  border-left: 3px solid currentcolor;
  padding: 0.2em 0.9em;
  margin: 0;
  font-size: 1.2em;
}
.notion-hr {
  margin: 6px 0px;
  padding: 0;
  border-top-width: 1px;
  border-bottom-width: 0;
  border-color: var(--fg-color-0);
}
.notion-link {
  color: inherit;
  word-break: break-word;
  text-decoration: underline;
  text-decoration-color: inherit;
}
.notion-blank {
  min-height: 1rem;
  padding: 3px 2px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.notion-page-link {
  display: flex;
  color: var(--fg-color);
  text-decoration: none;
  height: 30px;
  margin: 1px 0px;
  transition: background 120ms ease-in 0s;
}
.notion-page-link:hover {
  background: rgba(55,53,47, 0.08);
}

.notion-page-icon {
  line-height: 1.4;
  margin-right: 4px;
  margin-left: 2px;
}
img.notion-page-icon {
  display: block;
  object-fit: cover;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.notion-icon {
  display: block;
  width: 18px;
  height: 18px;
  color: var(--fg-color-2);
}

.notion-page-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 1.3;
  border-bottom: 1px solid var(--fg-color-1);
  margin: 1px 0px;
}

.notion-inline-code {
  color: #eb5757;
  padding: 0.2em 0.4em;
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  font-size: 85%;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
  monospace;
}

.notion-list {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

.notion-list-disc {
  list-style-type: disc;
  padding-inline-start: 1.6em;
  margin-top: 0px;
  margin-bottom: 0px;
}
.notion-list-numbered {
  list-style-type: decimal;
  padding-inline-start: 1.6em;
  margin-top: 0px;
  margin-bottom: 0px;
}

.notion-list-disc li {
  padding-left: 0.1em;
}

.notion-list-numbered li {
  padding-left: 0.2em;
}

.notion-list li {
  padding: 4px 0px;
  white-space: pre-wrap;
}

.notion-list > .notion-text {
  margin-left: -1.6em;
  padding-left: 0px;
}

.notion-asset-wrapper {
  margin: 0.5rem auto 0.5rem;
  max-width: 100%;
}

.notion-asset-wrapper > img {
  max-width: 100%;
}

.notion-asset-wrapper iframe {
  border: none;
}

.notion-text {
  white-space: pre-wrap;
  caret-color: var(--fg-color);
  padding: 3px 2px;
}
.notion-block {
  padding: 3px 2px;
}

.notion .notion-code {
  font-size: 85%;
}

.notion-code {
  padding: 30px 16px 30px 20px;
  margin: 4px 0;
  border-radius: 3px;
  tab-size: 2;
  display: block;
  box-sizing: border-box;
  overflow-x: scroll;
  background: rgb(247, 246, 243);
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
  monospace;
}

.notion-column {
  padding-top: 12px;
  padding-bottom: 12px;
}

.notion-column > *:first-child {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.notion-column > *:last-child {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.notion-row {
  display: flex;
  overflow: hidden;
}

.notion-bookmark {
  margin: 4px 0;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid var(--fg-color-1);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  user-select: none;
}

.notion-bookmark > div:first-child {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  overflow: hidden;
  text-align: left;
  color: var(--fg-color);
}

.notion-bookmark-title {
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
  margin-bottom: 2px;
}

.notion-bookmark-description {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
  height: 32px;
  overflow: hidden;
}

.notion-bookmark-link {
  display: flex;
  margin-top: 6px;
}

.notion-bookmark-link > img {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 6px;
}

.notion-bookmark-link > div {
  font-size: 12px;
  line-height: 16px;
  color: var(--fg-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-bookmark-image {
  flex: 1 1 180px;
  position: relative;
}

.notion-bookmark-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.notion-column .notion-bookmark-image {
  display: none;
}

@media (max-width: 640px) {
  .notion-bookmark-image {
    display: none;
  }

  .notion-row {
    flex-direction: column;
  }

  .notion-row > *,
  .notion-column > * {
    width: 100% !important;
  }
}

.notion-spacer:last-child {
  display: none;
}

.notion-image-inset {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 1px;
}

.notion-image-caption {
  padding: 6px 0px;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: var(--fg-color);
  font-size: 14px;
  line-height: 1.4;
  color: var(--fg-color-3);
}

.notion-callout {
  padding: 16px 16px 16px 12px;
  display: inline-flex;
  width: 100%;
  border-radius: 3px;
  border-width: 1px;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 4px 0;
}
.notion-callout-text {
  margin-left: 8px;
  white-space: pre-line;
}

.notion-toggle {
  padding: 3px 2px;
}
.notion-toggle > summary {
  cursor: pointer;
  outline: none;
}
.notion-toggle > div {
  margin-left: 1.1em;
}

.notion-table,
.notion-th,
.notion-td {
  border: 1px solid var(--fg-color-0);
  border-collapse: collapse;
}

.notion-table {
  width: 100%;
  border-left: none;
  border-right: none;
  border-spacing: 0px;
  white-space: nowrap;
}

.notion-th,
.notion-td {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
  font-size: 14px;
}

.notion-td.notion-bold {
  font-weight: 500;
}

.notion-th {
  color: var(--fg-color-3);
  font-size: 14px;
}

.notion-td:first-child,
.notion-th:first-child {
  border-left: 0;
}

.notion-td:last-child,
.notion-th:last-child {
  border-right: 0;
}

.notion-gallery {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: 1fr;
  gap: 16px;
  border-top: 1px solid var(--fg-color-1);
  padding-top: 16px;
  padding-bottom: 4px;
}
.notion-gallery-card {
  display: block;
  color: inherit;
  text-decoration: none;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
  rgba(15, 15, 15, 0.1) 0px 2px 4px;
  border-radius: 3px;
  background: white;
  overflow: hidden;
  transition: background 100ms ease-out 0s;
  position: static;
  height: 100%;
}

.notion-gallery-content {
  padding: 8px 10px 6px;
  font-size: 12px;
  white-space: nowrap;
}

.notion-gallery-data.is-first {
  white-space: nowrap;
  word-break: break-word;
  caret-color: var(--fg-color);
  font-size: 14px;
  line-height: 1.5;
  min-height: 21px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notion-page-header {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 45px;
  min-height: 45px;
  display: flex;
  background: #fff;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  text-size-adjust: 100%;
  line-height: 1.5;
  line-height: 1.2;
  font-size: 14px;
  background-color: var(--bg-color);

  z-index: 1500;
}

.notion-nav-breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  flex-grow: 0;
  min-width: 0;
  margin-right: 8px;
}

.notion-nav-breadcrumb {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  color: var(--fg-color);
  text-decoration: none;
  margin: 1px 0px;
  padding: 4px 6px;
  border-radius: 3px;
  transition: background 120ms ease-in 0s;
  user-select: none;
  background: transparent;
  cursor: pointer;
}

img.notion-nav-icon {
  width: 18px !important;
  height: 18px !important;
}

.notion-nav-icon {
  font-size: 18px;
  margin-right: 6px;
  line-height: 1.1;
  color: #000;
}

.notion-nav-breadcrumb:not(.notion-nav-breadcrumb-active):hover {
  background: rgba(55,53,47, 0.08);
}

.notion-nav-breadcrumb:not(.notion-nav-breadcrumb-active):active {
  background: var(--fg-color-1);
}

.notion-nav-breadcrumb.notion-nav-breadcrumb-active {
  cursor: default;
}

.notion-nav-spacer {
  margin: 0 2px;
  color: var(--fg-color-2);
}
