@import './swiper-vars.scss';

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  &.swiper-pagination-hidden {
    opacity: 0;
  }
}

.swiper-pagination-disabled > .swiper-pagination,
.swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 8px;
  right: 8px;

  padding: 4px 8px;
  opacity: 0.8;
  border-radius: 6px;
  background-color: #222;

  color: #fff !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 1 !important;
}

.swiper-horizontal > .swiper-pagination-bullets {
  @extend .swiper-pagination-fraction;
  left: 50%;
  transform: translate3D(-50%, 0, 0);
  bottom: 16px;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0 !important;

  .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
  }

  .swiper-pagination-bullet-active {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-main {
    transform: scale(1);
  }

  .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
  }

  .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
  }

  .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
  }
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #e8e8e8;
  opacity: 0.2;
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
  }
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet:only-child {
  display: none !important;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffffff;
}

.swiper-vertical {
  & > .swiper-pagination-bullets,
  .swiper-pagination-vertical.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
  }
  & > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block;
  }
  & > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }
  & > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
  .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: 200ms transform, 200ms top;
  }
}
.swiper-horizontal {
  & > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
  }
  & > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  & > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms left;
  }
  &.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
  :host(.swiper-horizontal.swiper-rtl) .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
}

/* Fraction */
.swiper-pagination-fraction {
  color: #ffffff;
}
/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  /*ADD_HOST*/
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: $themeColor;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-lock {
  display: none;
}
